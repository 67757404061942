import React from 'react'
import { Box, Typography, TypographyProps } from '@mui/material'

export interface FieldProps {
  label: string
  value?: string | null,
  children?: React.ReactNode
  valueSx?: TypographyProps['sx']
  labelSx?: TypographyProps['sx']
}
export default function Field({ label, value, children, valueSx, labelSx }: FieldProps) {
  return (
    <Box>
      <Typography component='p' variant='caption' fontWeight='bold' textTransform='uppercase' align='left' color='secondary' sx={labelSx}>{label}</Typography>

      {children || <Typography component='p' variant='body1' align='left' color='black' sx={valueSx}>{value}</Typography>}
    </Box>
  )
}
