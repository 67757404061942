import React from 'react'

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'

export const PAGES = {
  APROVACAO: {
    id: 'aprovacao',
    url: '/aprovacao',
    name: 'Aprovação dos CNABs',
    icon: <AssignmentTurnedInIcon />
  }
}

export const DATE_FORMAT = {
  DAILY: 'MM/dd/yyyy',
  MONTHLY: 'MM/yyyy'
}

export const CSV_ENCODING = 'ISO-8859-1'
export const CSV_DELIMITER = ';'
export const BUCKET_NAME = 'atrio-warehouse'

export type SYSTEM_STATUS = 'aprovado' | 'reprovado' | 'ressalvas' | 'pendente' | 'invalido'

export const STATE_MACHINE_NAMES = {
  LIMIT_LAYER: 'CnabLimitLayerStarter',
  POS_APPROVAL: 'CnabPosApprovalStarter'
}

export const SQS_PROVIDER_NAMES = {
  CNAB_APPROVAL: STATE_MACHINE_NAMES.LIMIT_LAYER,
  POS_APPROVAL: STATE_MACHINE_NAMES.POS_APPROVAL
}

export const FETCH_KEY = 'list-cnabs-uploaded-today' // key used to fetch list of CNABs uploaded today
export const FETCH_INTERVAL = 5 * 60 * 1000 // 5 minute in milliseconds
