import pack from '../../package.json'

import logoBoaNota from '../assets/img/logo-boanota.svg'
import logoCarmel from '../assets/img/logo-carmel.png'
import logoIvtGrade from '../assets/img/logo-ivtgrade.svg'

export const VERSION = pack.version
export const ENV: string = process.env.REACT_APP_ENV ?? 'development'
export const STAGE: string = (process.env.REACT_APP_STAGE ?? '').toLowerCase()
export const SCOPE = process.env.REACT_APP_SCOPE || ''
export const SCOPE_GROUP = process.env.REACT_APP_SCOPE_GROUP || 'boanota_front_aprovacao_cnab'

console.log(`v${VERSION} - ${ENV}`)

//  AWS COGNITO ENV
export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION
export const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID
export const AWS_USER_POOLS_WEB_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
export const AWS_COGNITO_AUTH_DOMAIN = process.env.REACT_APP_AWS_COGNITO_AUTH_DOMAIN || ''
export const AWS_COGNITO_SHARED_DOMAIN = process.env.REACT_APP_SHARED_DOMAIN || ''

// AWS S3 ENV
export const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID || ''
export const AWS_IDENTITY_POOL_REGION = process.env.REACT_APP_AWS_IDENTITY_POOL_REGION || 'us-east-1'

const commonEnvs: { [key: string]: string } = {
}

const configs: { [envKey: string]: { [key: string]: string } } = {
  prod: {
    SQS_BASE_URL: 'https://wtk56jffii.execute-api.us-east-1.amazonaws.com/Prod',
    PORTAL_FIDICT_BASE_URL: ''
  },
  staging: {
    SQS_BASE_URL:
      'https://bcnjvqsdy4.execute-api.us-east-1.amazonaws.com/Staging',
    PORTAL_FIDICT_BASE_URL: 'https://kxelhfknfj.execute-api.us-east-1.amazonaws.com/Staging'
  },
  dev: {
    SQS_BASE_URL: 'https://4vqjtfkgu6.execute-api.us-east-1.amazonaws.com/Dev',
    PORTAL_FIDICT_BASE_URL: 'https://66e7q5xjb7.execute-api.us-east-1.amazonaws.com/Dev'
  }
}

const currentEnvs = { ...commonEnvs, ...configs[STAGE] }

export const { PORTAL_FIDICT_BASE_URL, SQS_BASE_URL } = currentEnvs

export const HEAD_TITLE = process.env.REACT_APP_HEAD_TITLE || ''
export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR || ''
export const SECONDARY_COLOR = process.env.REACT_APP_SECONDARY_COLOR || ''
export const LIST_BACKGROUND_COLOR = process.env.REACT_APP_LIST_BACKGROUND_COLOR || ''
export const LIST_COLOR = process.env.REACT_APP_LIST_COLOR || ''

const resolveLogo = (scope: string) => {
  switch (scope.toLowerCase()) {
    case 'atrio':
      return logoCarmel
    case 'ivtgrade':
      return logoIvtGrade
    default:
      return logoBoaNota
  }
}

export const LOGO = resolveLogo(SCOPE)

const verifyEnv = () => {
  const envs = {
    VERSION,
    ENV,
    AWS_COGNITO_REGION,
    AWS_USER_POOLS_ID,
    AWS_USER_POOLS_WEB_CLIENT_ID,
    AWS_IDENTITY_POOL_ID,
    AWS_IDENTITY_POOL_REGION,
    SCOPE,
    STAGE,
    AWS_COGNITO_AUTH_DOMAIN,
    AWS_COGNITO_SHARED_DOMAIN
  }

  Object.entries(envs).forEach(([name, value]) => {
    if ([undefined, null, ''].includes(value)) {
      throw new Error(`${name} is not defined, current value is ${value}`)
    }
  })
}

verifyEnv()
